.popup-youtube{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms $easeOutExpo, visibility 0ms linear 600ms;
  cursor: pointer;
  &.is-active{
    opacity: 1;
    visibility: visible;
    transition: opacity 1200ms $easeOutExpo, visibility 0ms linear 0ms;
    .popup-youtube-close{
      span{
        width: #{$base*1.4}px;
        &.close-1{
          transition: width 600ms $easeOutExpo 600ms;
        }
        &.close-2{
          transition: width 600ms $easeOutExpo 1000ms;
        }
      }
    }
  }
  &:before{
    content: '';
    position: fixed;
    top: -20%;
    left: -20%;
    z-index: 0;
    display: block;
    width: 140%;
    height: 140%;
    background-color: $color-bg;
  }
  .popup-youtube-container{
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 700px;
    padding: #{$base/2}px 0;
    opacity: 0;
    transition: opacity 600ms $easeOutExpo;
    &.is-active{
      opacity: 1;
      transition: opacity 1200ms $easeOutExpo;
    }
    .popup-youtube-element{
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
    }
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .popup-youtube-close{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: block;
    width: #{$base}px;
    height: #{$base}px;
    span{
      width: 0px;
      display: block;
      position: absolute;
      top: -0.5Px;
      height: 1Px;
      background-color: $color-primary;
      &.close-1{
        left: 0;
        transform: rotate(45deg);
        transform-origin: left top;
        transition: width 300ms $easeOutExpo;
      }
      &.close-2{
        right: -0.5Px;
        transform: rotate(-45deg);
        transform-origin: right top;
        transition: width 300ms $easeOutExpo 200ms;
      }
    }
  }
}