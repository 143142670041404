$underscore: '', '_', '__','___','____','_____','______';
$param: 0.5, 1, 2, 4, 6, 8, 10;
@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}row{
    padding-top: #{$margin}px;
    &:first-child,
    &.is-row-no-top{
      padding-top: 0;
    }
    &.is-row-top{
      padding-top: #{$margin}px;
    }
    &.is-row-medium-top{
      padding-top: #{$margin*1.5}px;
    }
    &.is-row-large-top{
      padding-top: #{$margin*2}px;
    }
    &._row-top-portrait{
      @include breakpoint(portrait){
        padding-top: #{$margin}px;
      }
    }
    &.is-row-small-bottom{
      padding-bottom: #{$margin/2}px;
    }
    &.is-row-bottom{
      padding-bottom: #{$margin}px;
    }
    &.is-row-medium-bottom{
      padding-bottom: #{$margin*1.5}px;
    }
    &.is-row-large-bottom{
      padding-bottom: #{$margin*2}px;
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}row-pc-small{
    @include breakpoint(pc-small){
      padding-top: #{$margin}px;
    }
    &:first-child,
    &.is-row-no-top-pc-small{
      @include breakpoint(pc-small){
        padding-top: 0;
      }
    }
    &.is-row-top-pc-small{
      @include breakpoint(pc-small){
        padding-top: #{$margin}px;
      }
    }
    &.is-row-bottom-pc-small{
      @include breakpoint(pc-small){
        padding-bottom: #{$margin}px;
      }
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}row-tablet{
    @include breakpoint(tablet){
      padding-top: #{$margin}px;
    }
    &:first-child,
    &.is-row-no-top-tablet{
      @include breakpoint(tablet){
        padding-top: 0;
      }
    }
    &.is-row-top-tablet{
      @include breakpoint(tablet){
        padding-top: #{$margin}px;
      }
    }
    &.is-row-bottom-tablet{
      @include breakpoint(tablet){
        padding-bottom: #{$margin}px;
      }
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}row-sp{
    @include breakpoint(smartphone){
      padding-top: #{$margin}px;
    }
    &:first-child,
    &.is-row-no-top-sp{
      @include breakpoint(smartphone){
        padding-top: 0;
      }
    }
    &.is-row-top-sp{
      @include breakpoint(smartphone){
        padding-top: #{$margin}px;
      }
    }
    &.is-row-medium-top-sp{
      @include breakpoint(smartphone){
        padding-top: #{$margin*1.5}px;
      }
    }
    &.is-row-bottom-sp{
      @include breakpoint(smartphone){
        padding-bottom: #{$margin}px;
      }
    }
  }
}

.__row-sp-small2{
  @include breakpoint(smartphone-small2){
    padding-top: #{$base/4}px;
  }
}