.outline{
  width: 100%;
  @include breakpoint(smartphone){
    margin-bottom: #{$base/8}px;
  }
  &:last-child{
    dt,dd{
      margin-bottom: 1Px;
    }
  }
  dt{
    width: 125px;
    padding-right: 25px;
    padding-bottom: #{$base/8}px;
    margin-bottom: #{$base/8}px;
    border-bottom: solid 1PX rgba($color-primary,0.5);
    @include breakpoint(smartphone){
      width: 100%;
      padding-right: 0;
      margin-bottom: 0;
      padding-bottom: #{$base/16}px;
      border-bottom: none;
    }
    &.is-news{
      
    }
  }
  dd{
    width: calc(100% - 150px);
    padding-bottom: #{$base/8}px;
    margin-bottom: #{$base/8}px;
    border-bottom: solid 1PX $color-primary;
    @include breakpoint(smartphone){
      width: 100%;
      margin-bottom: 0;
    }
  }
}