/*
  param
*/
$base: 50;
$base-text: 13;

/*
  color
*/
$color-bg: #ffffff;
$color-bg-sub: #ededed;
$color-primary: #000000;
$color-primary-sub: lighten($color-primary,60%);
$color-border: lighten($color-primary,80%);
$color-accent: #00ffd8;

/*
  extend
*/
%transition{
  transition: color 1200ms ease, background-color 1200ms ease, border-color 1200ms ease, fill 1200ms ease;
}

/*
  animate
*/