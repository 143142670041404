.carousel-container{
  display: block;
  position: relative;
  z-index: 0;
  height: 0;
  width: 100%;
  padding-bottom: 66.666%;
  line-height: 0;
}
.carousel{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none !important;
  .flickity-viewport,
  .flickity-slider{
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
    width: 100%;
    height: 100%;
    outline: none;
  }
  .flickity-viewport{
    backface-visibility: hidden;
    overflow: hidden;
    background-color: $color-bg-sub;
  }
  .flickity-prev-next-button{
    position: absolute;
    top: 50%;
    border-radius: 0;
    background-color: transparent;
    border: solid 1Px $color-primary-sub;
    z-index: 10;
    width: 50px;
    height: 50px;
    -webkit-appearance: none;
    appearance: none;
    outline: none !important;
    margin-top: -25px;
    cursor: pointer;
    @include breakpoint(smartphone){
      display: none;
    }
    &.previous{
      left: 0;
    }
    &.next{
      right: 0;
    }
    .arrow{
      fill: $color-primary-sub;
    }
    &.hover{
      background-color: $color-primary;
      .arrow{
        fill: $color-bg;
      }
    }
  }
  .flickity-page-dots{
    position: absolute;
    bottom: -35Px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .dot{
      width: 15Px;
      height: 15Px;
      background-color: transparent;
      border: solid 1Px $color-primary-sub;
      border-radius: 50%;
      margin-left: 10Px;
      cursor: pointer;
      box-sizing: border-box;
      &.is-selected{
        background-color: $color-primary;
      }
      &:first-child{
        margin-left: 0;
      }
    }
  }
}