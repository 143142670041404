.textsize-xsmall{
  font-size: #{$base-text}px;
  line-height: #{$base-text*2}px;
  letter-spacing: 0.02em;
}
.textsize-small{
  font-size: #{$base-text*1.25}px;
  line-height: #{$base-text*2}px;
  letter-spacing: 0.02em;
}
.textsize-regular{
  font-size: #{$base-text*1.5}px;
  line-height: #{$base-text*2.8}px;
  letter-spacing: 0.02em;
}
.textsize-medium{
  font-size: #{$base-text*2}px;
  line-height: #{$base-text*3.5}px;
  letter-spacing: 0.02em;
}
.textsize-large{
  font-size: #{$base-text*3}px;
  line-height: #{$base-text*4.5}px;
  letter-spacing: 0.02em;
}
.textsize-regular-sp{
  @include breakpoint(smartphone){
    font-size: #{$base-text*1.5}px;
    line-height: #{$base-text*2.8}px;
    letter-spacing: 0.02em;
  }
}
.textsize-medium-sp{
  @include breakpoint(smartphone){
    font-size: #{$base-text*2}px;
    line-height: #{$base-text*3.5}px;
    letter-spacing: 0.02em;
  }
}