$underscore: '', '_', '__';
$param: 1, 2, 4;
@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}grid-container{
    box-sizing: border-box;
    margin-left: -#{$margin}px;
    > *{
      box-sizing: border-box;
      padding-left: #{$margin}px;
    }
    &.is-grid-bottom{
      > *{
        padding-bottom: #{$margin}px;
      }
      &.is-grid-bottom-minus{
        margin-bottom: -#{$margin}px;
      }
    }
    &.is-grid-large-bottom{
      > *{
        padding-bottom: #{$margin*2}px;
      }
      &.is-grid-large-bottom-minus{
        margin-bottom: -#{$margin*2}px;
      }
    }
    &.is-grid-half-bottom{
      > *{
        padding-bottom: #{$margin/2}px;
      }
      &.is-grid-half-bottom-minus{
        margin-bottom: -#{$margin/2}px;
      }
    }
    &.is-break-tablet,
    &.is-grid-break-tablet{
      @include breakpoint(tablet){
        box-sizing: initial;
        margin-left: 0;
        margin-bottom: 0;
      }
      > *{
        @include breakpoint(tablet){
          box-sizing: initial;
          padding-left: 0;
          padding-bottom: 0;
        }
        &:before{
          @include breakpoint(tablet){
            content: none;
          }
        }
      }
    }
    &.is-break-sp,
    &.is-grid-break-sp{
      @include breakpoint(smartphone){
        box-sizing: initial;
        margin-left: 0;
        margin-bottom: 0;
      }
      > *{
        @include breakpoint(smartphone){
          box-sizing: initial;
          padding-left: 0;
          padding-bottom: 0;
        }
        &:before{
          @include breakpoint(smartphone){
            content: none;
          }
        }
      }
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}grid-container-pc-small{
    @include breakpoint(pc-small){
      box-sizing: border-box;
      margin-left: -#{$margin}px;
    }
    > *{
      @include breakpoint(pc-small){
        box-sizing: border-box;
        padding-left: #{$margin}px;
      }
    }
    &.is-grid-bottom-pc-small,
    &.is-grid-break-pc-small{
      @include breakpoint(pc-small){
        margin-bottom: -#{$margin}px;
      }
      > *{
        @include breakpoint(pc-small){
          padding-bottom: #{$margin}px;
        }
      }
    }
    &.is-grid-medium-bottom-pc-small{
      @include breakpoint(pc-small){
        margin-bottom: -#{$margin*1.5}px;
      }
      > *{
        @include breakpoint(pc-small){
          padding-bottom: #{$margin*1.5}px;
        }
      }
    }
    &.is-break-sp,
    &.is-grid-break-sp{
      @include breakpoint(smartphone){
        box-sizing: initial;
        margin-left: 0;
        margin-bottom: 0;
      }
      > *{
        @include breakpoint(smartphone){
          box-sizing: initial;
          padding-left: 0;
          padding-bottom: 0;
        }
        &:before{
          @include breakpoint(smartphone){
            content: none;
          }
        }
      }
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}grid-container-tablet{
    @include breakpoint(tablet){
      box-sizing: border-box;
      margin-left: -#{$margin}px;
    }
    > *{
      @include breakpoint(tablet){
        box-sizing: border-box;
        padding-left: #{$margin}px;
      }
    }
    &.is-grid-bottom-tablet,
    &.is-grid-break-tablet{
      @include breakpoint(tablet){
        margin-bottom: -#{$margin}px;
      }
      > *{
        @include breakpoint(tablet){
          padding-bottom: #{$margin}px;
        }
      }
    }
    &.is-grid-medium-bottom-tablet{
      @include breakpoint(tablet){
        margin-bottom: -#{$margin*1.5}px;
      }
      > *{
        @include breakpoint(tablet){
          padding-bottom: #{$margin*1.5}px;
        }
      }
    }
    &.is-break-sp,
    &.is-grid-break-sp{
      @include breakpoint(smartphone){
        box-sizing: initial;
        margin-left: 0;
        margin-bottom: 0;
      }
      > *{
        @include breakpoint(smartphone){
          box-sizing: initial;
          padding-left: 0;
          padding-bottom: 0;
        }
        &:before{
          @include breakpoint(smartphone){
            content: none;
          }
        }
      }
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}grid-container-sp{
    @include breakpoint(smartphone){
      box-sizing: border-box;
      margin-left: -#{$margin}px;
    }
    > *{
      @include breakpoint(smartphone){
        box-sizing: border-box;
        padding-left: #{$margin}px;
      }
    }
    &.is-grid-bottom-sp{
      > *{
        @include breakpoint(smartphone){
          padding-bottom: #{$margin}px;
        }
      }
      &.is-grid-bottom-minus-sp{
        @include breakpoint(smartphone){
          margin-bottom: -#{$margin}px;
        }
      }
    }
    &.is-grid-large-bottom-sp{
      > *{
        @include breakpoint(smartphone){
          padding-bottom: #{$margin*2}px;
        }
      }
    }
    &.is-grid-xlarge-bottom-sp{
      > *{
        @include breakpoint(smartphone){
          padding-bottom: #{$margin*4}px;
        }
      }
    }
    &.is-grid-half-bottom-sp{
      > *{
        @include breakpoint(smartphone){
          padding-bottom: #{$margin/2}px;
        }
      }
    }
  }
}

@each $u in $underscore {
  $i: index($underscore, $u);
  $p: nth($param, $i);
  $margin: $base/$p;
  .#{$u}grid-container-sp-small{
    @include breakpoint(smartphone-small){
      box-sizing: border-box;
      margin-left: -#{$margin}px;
    }
    > *{
      @include breakpoint(smartphone-small){
        box-sizing: border-box;
        padding-left: #{$margin}px;
      }
    }
    &.is-grid-bottom-sp-small{
      @include breakpoint(smartphone-small){
        margin-bottom: -#{$margin}px;
      }
      > *{
        @include breakpoint(smartphone-small){
          padding-bottom: #{$margin}px;
        }
      }
    }
  }
}