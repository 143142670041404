/*
  base
*/
html{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-size-adjust: 100%;
  font-feature-settings: 'palt' 1;
  @include breakpoint(smartphone){
    font-size: 9Px !important;
  }
  @include breakpoint(smartphone-small){
    font-size: 8Px !important;
  }
}
body{
  @extend .font-sans-serif;
  @extend .textsize-regular;
  background-color: $color-bg;
}
a{
  text-decoration: none;
  color: $color-primary;
}
svg{
  width: 100%;
  height: auto;
}
hr{
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 1Px;
  background-color: $color-border;
}
.icon{
  width: 40px;
  line-height: 0;
  display: inline-block;
  &.hover{
    opacity: 0.5;
  }
}
.container{
  position: relative;
  z-index: 0;
  padding-top: #{$base*3.25}px;
}