.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  display: none;
  &.is-active{
    display: block;
  }
  .modal-close-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .modal-container{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%,-50%);
    padding: #{$base}px;
    max-width: 500px;
    @include breakpoint(smartphone){
      width: calc(100% - #{$base}px);
      box-sizing: border-box;
      padding: #{$base/2}px;
    }
  }
}