.iframe-container{
  position: relative;
  height: 0;
  line-height: 0;
  padding-bottom: 56.56%;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}