.textcolor-bg{
  color: $color-bg;
}
.textcolor-primary-sub{
  color: $color-primary-sub;
}
.textcolor-primary,
.linkcolor-primary a{
  color: $color-primary;
}
.textcolor-accent{
  color: $color-accent;
}