@font-face {
  font-family: 'nb_international_pro';
  src: url('../fonts/nb_international_pro/nb_international_pro_light-webfont.woff2') format('woff2'),
          url('../fonts/nb_international_pro/nb_international_pro_light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'nb_international_pro';
  src: url('../fonts/nb_international_pro/nb_international_pro_regular-webfont.woff2') format('woff2'),
          url('../fonts/nb_international_pro/nb_international_pro_regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'nb_international_pro';
  src: url('../fonts/nb_international_pro/nb_international_pro_bold-webfont.woff2') format('woff2'),
        url('../fonts/nb_international_pro/nb_international_pro_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
