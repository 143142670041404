img{
  width: 100%;
  height: auto;
}
.image-container{
  display: block;
  position: relative;
  z-index: 0;
  height: 0;
  width: 100%;
  padding-bottom: 66.666%;
  line-height: 0;
  background-color: $color-bg-sub;
  &.is-no-bg{
    background-color: transparent;
  }
  &.is-square{
    padding-bottom: 100%;
  }
  &.is-fit{
    padding-bottom: 0;
    height: 100%;
  }
}
.bgimg{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &.is-contain{
    background-size: contain;
  }
  &.is-top-left{
    background-position: top left;
  }
}
.image-caption{
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  line-height: 1;
}
.preload-image{
  visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  &.loaded{
    visibility: visible;
    opacity: 1;
    transition: opacity 1200ms ease 400ms, visibility 0ms linear 0ms;
  }
}
.play-button{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%,-50%);
  width: #{$base*2}px;
  height: #{$base*2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    position: absolute;
    top: 0;
    left: 0;
    width: #{$base*2}px;
    height: #{$base*2}px;
    transition: transform 600ms $easeOutExpo;
    &:before{
      content: "";
      position: relative;
      top: -1Px;
      left: -1Px;
      width: 100%;
      height: 100%;
      display: block;
      border: solid 2Px $color-bg;
      border-radius: 50%;
      transition: border-color 600ms ease;
    }
  }
  svg{
    position: relative;
    top: #{$base/20}px;
    left: #{$base/10}px;
    width: #{$base/1.5}px;
    height: auto;
    fill: $color-bg;
    transition: fill 600ms ease;
    path{
      stroke: $color-bg;
      stroke-width: 2;
      transition: stroke 600ms ease;
    }
  }
}
.hover{
  .play-button{
    span{
      transform: scale(1.05);
      &:before{
        border-color: $color-accent;
      }
    }
    svg{
      fill: $color-accent;
      path{
        stroke: $color-accent;
      }
    }
  }
}
.is-box-shadow{
  -webkit-box-shadow: 0 13px 5px -7px rgb(90 90 90 / 30%), 0 0 5px rgb(90 90 90 / 10%);
  -webkit-box-shadow: 0 1.08333rem 0.41667rem -0.58333rem rgb(90 90 90 / 30%), 0 0 0.41667rem rgb(90 90 90 / 10%);
  box-shadow: 0 13px 5px -7px rgb(90 90 90 / 30%), 0 0 5px rgb(90 90 90 / 10%);
  box-shadow: 0 1.08333rem 0.41667rem -0.58333rem rgb(90 90 90 / 30%), 0 0 0.41667rem rgb(90 90 90 / 10%);
}