.flex-container{
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  &.is-flex-wrap-nowrap{
    flex-wrap: nowrap;
  }
  &.is-justify-content-center{
    justify-content: center;
  }
  &.is-justify-content-flex-end{
    justify-content: flex-end;
  }
  &.is-justify-content-space-between{
    justify-content: space-between;
  }
  &.is-align-items-center{
    align-items: center;
  }
  &.is-align-items-flex-end{
    align-items: flex-end;
  }
  &.is-flex-direction-row-reverse{
    flex-direction: row-reverse;
  }
  &.is-flex-direction-column{
    flex-direction: column;
  }
  &.is-justify-content-space-between-tablet{
    @include breakpoint(tablet){
      justify-content: space-between;
    }
  }
  &.is-justify-content-flex-start-sp{
    @include breakpoint(smartphone){
      justify-content: flex-start;
    }
  }
  &.is-align-items-flex-start-sp{
    @include breakpoint(smartphone){
      align-items: flex-start;
    }
  }
  &.is-break-tablet{
    @include breakpoint(tablet){
      display: block;
    }
  }
  &.is-break-sp{
    @include breakpoint(smartphone){
      display: block;
    }
  }
  &.is-break-sp-small{
    @include breakpoint(smartphone-small){
      display: block;
    }
  }
  &.is-break-sp-small2{
    @include breakpoint(smartphone-small2){
      display: block;
    }
  }
}

.flex-container-tablet{
  @include breakpoint(tablet){
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  &.is-flex-wrap-nowrap-tablet{
    @include breakpoint(tablet){
      flex-wrap: nowrap;
    }
  }
  &.is-justify-content-center-tablet{
    @include breakpoint(tablet){
      justify-content: center;
    }
  }
  &.is-justify-content-space-between-tablet{
    @include breakpoint(tablet){
      justify-content: space-between;
    }
  }
  &.is-align-items-flex-start-tablet{
    @include breakpoint(tablet){
      align-items: flex-start;
    }
  }
  &.is-flex-direction-column-tablet{
    @include breakpoint(tablet){
      flex-direction: column;
    }
  }
  &.is-break-sp{
    @include breakpoint(smartphone){
      display: block;
    }
  }
  &.is-break-sp-small{
    @include breakpoint(smartphone-small){
      display: block;
    }
  }
  &.is-break-sp-small2{
    @include breakpoint(smartphone-small2){
      display: block;
    }
  }
}

.flex-container-sp{
  @include breakpoint(smartphone){
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  &.is-flex-wrap-nowrap-sp{
    @include breakpoint(smartphone){
      flex-wrap: nowrap;
    }
  }
  &.is-align-items-center-sp{
    @include breakpoint(smartphone){
      align-items: center;
    }
  }
  &.is-flex-direction-column-sp{
    @include breakpoint(smartphone){
      flex-direction: column;
    }
  }
  &.is-justify-content-center-sp{
    @include breakpoint(smartphone){
      justify-content: center;
    }
  }
  &.is-justify-content-space-between-sp{
    @include breakpoint(smartphone){
      justify-content: space-between;
    }
  }
  &.is-break-sp-small{
    @include breakpoint(smartphone-small){
      display: block;
    }
  }
  &.is-break-sp-small2{
    @include breakpoint(smartphone-small2){
      display: block;
    }
  }
}

.flex-container-sp-small{
  @include breakpoint(smartphone-small){
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  &.is-flex-wrap-nowrap-sp{
    @include breakpoint(smartphone-small){
      flex-wrap: nowrap;
    }
  }
  &.is-align-items-center-sp{
    @include breakpoint(smartphone-small){
      align-items: center;
    }
  }
  &.is-justify-content-space-between-sp{
    @include breakpoint(smartphone-small){
      justify-content: space-between;
    }
  }
  &.is-break-sp-small2{
    @include breakpoint(smartphone-small2){
      display: block;
    }
  }
}

.flex-separate-slash{
  line-height: normal;
  > * {
    position: relative;
    padding-left: 3em;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 1.5em;
      display: block;
      width: 1Px;
      height: 120%;
      transform: rotate(45deg);
      background-color: $color-primary !important;
    }
    &:first-child{
      padding-left: 0;
      &:before{
        content: none;
        display: none;
      }
    }
  }
}

.flex-separate-comma{
  line-height: normal;
  &.is-first-none{
    > * {
      &:first-child{
        &:after{
          content: '';
        }
      }
    }
  }
  > * {
    &:after{
      content: ", ";
      margin-right: 0.5em;
    }
    &:last-child{
      &:after{
        content: none;
        margin-right: 0;
      }
    }
  }
}