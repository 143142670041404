.text{
  overflow-wrap : break-word;
  > p,> ol,> ul,> div,> h1,> h2,> h3,> h4,> h5,> h6, hr{
    margin-top: #{$base/6}px;
    &:first-child,
    &.is-text-no-top{
      margin-top: 0;
    }
    &.is-text-top{
      margin-top: #{$base/6}px;
    }
    &.is-text-half-top{
      margin-top: 0.5em;
    }
  }
  a{
    text-decoration: underline;
    &.hover,
    &.is-no-underline{
      text-decoration: none;
    }
  }
}
.writing-mode-vertical{
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-feature-settings: normal;
  display: inline-block;
  white-space: nowrap;
  &.is-writing-mode-break-tablet{
    @include breakpoint(tablet){
      -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
      font-feature-settings: 'palt';
    }
  }
  &.is-writing-mode-break-sp{
    @include breakpoint(smartphone){
      -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
      font-feature-settings: 'palt';
    }
  }
}