@mixin breakpoint($breakpoint) {
  @if $breakpoint == pc-large {
    @media ( min-width: 1700px ) { @content; }
  }
  @if $breakpoint == pc-small {
    @media ( max-width: 1200px ) { @content; }
  }
  @else if $breakpoint == tablet {
    @media ( max-width: 1023px ) { @content; }
  }
  @else if $breakpoint == smartphone {
    @media ( max-width: 767px ) { @content; }
  }
  @else if $breakpoint == smartphone-small {
    @media ( max-width: 413px ) { @content; }
  }
  @else if $breakpoint == smartphone-small2 {
    @media ( max-width: 374px ) { @content; }
  }
}